import React, { useRef } from "react"
import * as style from "../styles/contacto.module.css"
import Layout from "../components/layout/Layout"
import JsonData from "../data/country.json"
import JsonDataEs from "../data/countryEs.json"
import { Helmet} from "react-helmet";
import axios from 'axios';
import { graphql, navigate } from "gatsby"

// Toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Plugin i18next
import { useTranslation } from "gatsby-plugin-react-i18next"

let lambda_path = process.env.GATSBY_API_LAMBDA_PATH || "";

const ContactPage = () => {

    const submitRef = useRef(null) /** Referencia al boton de enviar */
    
    const { t, i18n } = useTranslation()

    const Title = ({title}) => {
        return (
            <h1> {title} </h1>
        )
    }

    /*  Clase que extiende de un componente para que cuando guarde en una variable los campos de prefijo o confirmar los terminos y condiciones, 
        esta no me refresque la página o borre el contenido de los campos de texto. Cuando usas useSatate para guardar algo en una variable te 
        borra el contenido de los inputs*/
    class ContactForm extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                onSubmit: false,
                isGoing: false,
                nombre: '',
                apellido: '',
                email: '',
                empresa: '',
                phoneValue: '+34',
                telefono: '',
                asunto: '',
                mensaje: '',
                error: false,
                web: 'Grupo GOF'
            }

            this.formularioContacto = t("Contact.title")

            this.placeholder = {
                nombre: t("Contact.nombre"),
                apellido: t("Contact.apellido"),
                email: t("Contact.email"),
                empresa: t("Contact.empresa"),
                telefono: t("Contact.telefono"),
                asunto: t("Contact.asunto"),
                mensaje: t("Contact.mensaje"),
            }

            //Texto de los terminos y servicios del checkbox de confirmación
            this.textTerminos = t("Contact.terminos")
            
            this.textButtonSend =  t("Contact.submit")

            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }


        /* Método que actualiza las variables de los componentes para recoger su información */
        handleChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }


        /* Método que maneja el envio de datos cuando le das al botón de enviar del formulario */
        async handleSubmit(event) {
            if (this.state.isGoing 
                && this.state.nombre !== '' 
                && this.state.apellido !== ''
                && this.state.email !== '' 
                && this.state.asunto !== ''
                && this.state.mensaje !== '') {
                //Enviar a lambda el JSON
                this.sendData()
            } else {
                this.setState({ onSubmit : true })
                toast.info(t("toast.incomplete"), {autoClose: 3000})
            }
            event.preventDefault();
        }

        sendData = async () => {
            // desactiva el boton de submit
            submitRef.current.disabled = true;
            toast(t("toast.sending"), {toastId: "sending"})

            // Simple POST request with a JSON body using axios
            const data = JSON.stringify({
                web : this.state.web,
                nombre : this.state.nombre, 
                apellido : this.state.apellido,
                empresa : this.state.empresa,
                email : this.state.email,
                prefijo : this.state.phoneValue,
                telefono : this.state.telefono,
                asunto : this.state.asunto,
                mensaje : this.state.mensaje
            });

            //Dirección de la url de la función de Lambda
            if (lambda_path === "") {
                console.log("No se ha guardado ninguna dirección lambda")
            } else {
                // Data se envía a la función lambda encriptado en base64 hay que desencriptarlo en la misma función lambda
                await axios.post(lambda_path, data)
                    // Sí la respuesta de la lambda devuelve OK es que no ha habido ningún problema y se ha enviado con éxito
                    .then(response => {
                        response.data === "OK" 
                            ?                           
                            localStorage.setItem("toastOK", "") /* Guarda el estado del envio correcto para ejecutar el toast en la pagina pricipal*/
                            : 
                            toast.error(t("toast.fail"), {autoClose: 3000})
                        }
                    )
                    .catch(error => {
                        this.setState({ errorMessage: error.message });
                        console.error('There was an error!', error);
                        toast.error(t("toast.error"), {autoClose: 3000})
                }).then(() => setTimeout(() => {
                    // cierra el toast de envio
                    toast.dismiss("sending");
                    // Reactiva submit
                    submitRef.current.disabled = false;
                    // Regresa a pagina principal si el envio es correcto
                    !(localStorage.getItem("toastOK") === null) && navigate("/");
                }, 1000));
                
            }
            
        }

        render() {
            return (
                <form onSubmit={this.handleSubmit} className={style.container}>
                    <Title title={this.formularioContacto}/>
                    <div className={style.itemsContainer}>
                        <div className={style.containerColumn}>
                            {/* Campos de: nombre y apellido */}
                            <input name="nombre" value={this.state.nombre} className={style.textBox} type="text"  placeholder={this.placeholder.nombre} onChange={this.handleChange}
                                style={this.state.onSubmit && this.state.nombre === ''? {borderColor: "red"} : {}}/>
                            <input name="apellido" value={this.state.apellido} className={style.textBox} type="text"  placeholder={this.placeholder.apellido} onChange={this.handleChange}
                                style={this.state.onSubmit && this.state.apellido === ''? {borderColor: "red"} : {}}/>
                        </div>
                        <div className={style.containerColumn}>
                            {/* Campos de: email y empresa */}
                            <input name="email" value={this.state.email} className={style.textBox} type="text"  placeholder={this.placeholder.email} onChange={this.handleChange}
                                style={this.state.onSubmit && this.state.email === ''? {borderColor: "red"} : {}}/>
                            <input name="empresa" value={this.state.empresa} className={style.textBox} type="text"  placeholder={this.placeholder.empresa} onChange={this.handleChange}/>
                        </div>
                        <div className={style.containerColumn}>
                            {/* Selector del país */}
                            <select name="phoneValue" className={style.textBox} defaultValue={this.state.phoneValue} onChange={this.handleChange}>
                                {i18n.language === "en" ?
                                    Array.from(JsonData).sort((a, b) => a.country.name > b.country.name ? 1 : -1).map((data, index) => (
                                        <option key={index + data.country.name} value={data.country.code}>{data.country.name}</option>
                                    ))
                                :
                                    Array.from(JsonDataEs).sort((a, b) => a.country.name > b.country.name ? 1 : -1).map((data, index) => (
                                        <option key={index + data.country.name} value={data.country.code}>{data.country.name}</option>
                                    ))
                                }
                            </select>
                            <div style={{display:"flex", flexDirection:"row", width: "100%", gap: "10px"}}>
                                {/* Prefijo */}
                                <input value={this.state.phoneValue} className={`${style.textBox} ${style.textPhoneValue}`} type="text" disabled={true} onChange={this.handleChange}/>
                                {/* Número de teléfono */}
                                <input className={style.textBox}
                                name="telefono"
                                value={this.state.telefono}
                                style={{width: "90%"}}
                                type="number" 
                                placeholder={this.placeholder.telefono}
                                onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className={style.itemsContainer} style={{flexDirection:"column"}}>
                        {/* Campos de: asunto y mensaje */}
                        <input name="asunto" value={this.state.asunto} className={style.textBox} type="text"  placeholder={this.placeholder.asunto} onChange={this.handleChange}
                            style={this.state.onSubmit && this.state.asunto === ''? {borderColor: "red"} : {}}/>
                        <textarea className={`${style.textBox} ${style.textarea}`}
                            name="mensaje"
                            value={this.state.mensaje}
                            style={this.state.onSubmit && this.state.mensaje === ''? {borderColor: "red"} : {}}
                            placeholder={this.placeholder.mensaje}
                            onChange={this.handleChange}/>
                    </div>
                    {/* Confirmar los terminos y condiciones... */}
                    <div className={style.checkContainer}>
                        <input 
                        name="isGoing"
                        className={style.checkBox} 
                        type="checkbox"
                        checked={this.state.isGoing}
                        onChange={this.handleChange}/>
                        <label htmlFor="text" style={this.state.onSubmit && !this.state.isGoing ? {color: "red"} : {}}><p id="text">{this.textTerminos}</p></label>
                    </div>
                    <input ref={submitRef} type="submit" value={this.textButtonSend} className={style.buttonSendContact} />
                    <ToastContainer position="top-center"
                                    hideProgressBar={false}
                                    newestOnTop
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss={false}
                                    draggable
                                    pauseOnHover={false}
                                    theme="colored"/>
                </form>
            )
        }
    }

  return (
    <Layout href="../" link="./">
        <ContactForm/>
    </Layout>
  )
}

export default ContactPage

export function Head() {
  
    return (
      <>
        <Helmet>
            <html lang='es'/>
            <title>GRUPO GOF</title>
            <meta name="description" content="Página desarrollada por Nortic" />
            <link rel="icon" href="https://grupogof.com/wp-content/uploads/2018/10/icono-300x300.png" type="image/png"></link>
        </Helmet>
      </>
    )
  }

  export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;